<template>
  <span class="property-exclusive-badge" v-tooltip="'Exclusive'">{{
    full ? "Exclusive" : "EX"
  }}</span>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    full: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.property-exclusive-badge {
  background: linear-gradient(to right, #aa771c, #d3b06a);
  color: #fcf6ba;
  font-weight: bold;
  font-size: 10px;
  padding: 1px 8px;
  margin-right: 4px;
  border-radius: 50em;
  height: fit-content;
}
</style>
